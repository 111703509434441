/*Common CSS*/

/* Base button styles */
.button-activate,
.button-purchase,
.button-deactivate,
.button-print,
.button-check,
.button-search,
.button-submit,
.button-add,
.button-edit,
.button-exit,
.button-logout,
.button-delete,
.button-leave,
.button-remove,
.dropdown-button-settings {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Specific button colors */
.button-activate { background-color: #4CAF50; }
.button-activate:hover { background-color: #45a049; }

.button-purchase { background-color: #FFC107; color: black; }
.button-purchase:hover { background-color: #ffb300; }

.button-deactivate { background-color: #f44336; width: 100%;}
.button-deactivate:hover { background-color: #d32f2f; }

.button-print { background-color: #2196F3; }
.button-print:hover { background-color: #1e88e5; }

.button-check { background-color: #008CBA; }
.button-check:hover { background-color: #0077b6; }

.button-search { width: 10%; background-color: #008CBA; }
.button-search:hover { background-color: #0077b6; }

.button-submit { background-color: #30acfd; width: 100%; }
.button-submit:hover { background-color: #2795d9; }

.button-add { background-color: #30acfd; width: 100%; }
.button-add:hover { background-color: #2795d9; }

.button-edit { background-color: #638af5; width: 100%; }
.button-edit:hover { background-color: #30acfd; }

.button-exit {
    top: 10px;
    right: 10px;
    padding: 0;
    border-radius: 50%;
    border: none;
    background-color: rgb(241, 81, 81);
    font-size: 1.2em;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}
.button-exit:hover {
    background-color: #f00;
    color: #000;
}

.button-logout { background-color: #f44336; width: 100%; }
.button-logout:hover { background-color: #d32f2f; }

.button-delete { background-color: #f44336; }
.button-delete:hover { background-color: #d32f2f; }

.button-leave { background-color: #f44336; }
.button-leave:hover { background-color: #d32f2f; }

.button-remove { background-color: #ed901d; width: 100%;}
.button-remove:hover { background-color: #f48b0c; }

.dropdown-button-settings {
    background-color: transparent;
    color: black;
    padding: 10px;
    text-decoration: none;
    display: block;
    width: 100%;
}
.dropdown-button-settings:hover {
    background-color: #30acfd;
    color: #fff;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .button-activate,
    .button-purchase,
    .button-deactivate,
    .button-print,
    .button-check,
    .button-search,
    .button-submit,
    .button-add,
    .button-edit,
    .button-exit,
    .button-logout,
    .button-delete,
    .button-leave,
    .button-remove,
    .dropdown-button-settings {
        padding: 8px;
        font-size: 0.8rem;
    }

    .button-search {
        width: 15%; /* Adjust width for smaller screens */
    }

    .button-exit {
        width: 16px;
        height: 16px;
        line-height: 16px;
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .button-activate,
    .button-purchase,
    .button-deactivate,
    .button-print,
    .button-check,
    .button-search,
    .button-submit,
    .button-add,
    .button-edit,
    .button-exit,
    .button-logout,
    .button-delete,
    .button-leave,
    .button-remove,
    .dropdown-button-settings {
        padding: 6px;
        font-size: 0.7rem;
    }

    .button-search {
        width: 20%; /* Adjust width for smaller screens */
    }

    .button-exit {
        width: 14px;
        height: 14px;
        line-height: 14px;
        font-size: 0.8rem;
    }
}


.password-toggle {
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-60%);
  cursor: pointer;
  color: #000;
}


.error-message {
  color: red;
  margin-top: 10px;
}

.popup {
  padding: 15px;
  border: 1px solid #ddd;
  background-color: #f0f0f0;
  position: absolute;
  right: 20px;
  top: 60px;
  z-index: 10;
  border-radius: 5px;
  width: calc(100% - 30px); /* Adjust the width considering the padding */
}

.popup input[type="text"],
.popup input[type="email"],
.popup input[type="tel"],
.popup input[type="file"] {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%; /* Set width to 100% */
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding and border are included in the width calculation */
}

.white-page-content {
  min-height: 100vh;
  flex-grow: 1;
  background-color: #fff;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */

  padding-top: 80px;
  padding-bottom: 80px;
}

.desktop-only-container {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #30acfd;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logihash-desktop-user {
  margin: 20px 0; /* Adjust spacing around the image */
  max-width: 60%;
  height: auto;
  border: 2px solid #30acfd;
  border-radius: 10px;
}

/*Tables common style*/


.table-main-container {
  font-family: Arial, sans-serif;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  /*background-color: #fff;*/
  /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);*/
  border-radius: 8px;
}

.table-main-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.table-main-container  form {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 20px;
}

.table-main-container  form label {
  display: block;
  margin-bottom: 5px;
}

.table-main-container  form input[type="text"],
.table-main-container  form input[type="email"],
.table-main-container  form input[type="tel"] {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
}

.table-main-container  form input[type="file"] {
  margin-bottom: 15px;
}


@media (max-width: 1024px) {
  .table-main-container {
      padding: 10px;
  }

  .table-main-container .popup {
      width: 90%;
      left: 5%;
      top: 10px;
  }
}

@media (max-width: 1024px) {
  .table-main-container {
      width: 95%;
      padding: 10px;
  }


}

.table-container {
  width: auto;
  border-collapse: collapse;
  margin-top: 20px;
}

.table-container th, .table-container td {
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
  white-space: nowrap; /* Prevent text wrapping */

}

.table-container th {
  background-color: #f4f4f4;
  color: #333;
}

.table-container tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table-container  tr:hover {
  background-color: #ddd; /* Hover effect for rows */
}

.table-container td {
  color: #555;
}


.table-container  form {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 20px;
}

.table-container  form label {
  display: block;
  margin-bottom: 5px;
}

.table-container  form input[type="text"],
.table-container  form input[type="email"],
.table-container  form input[type="tel"] {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
}

.table-container  form input[type="file"] {
  margin-bottom: 15px;
}




/* Header Style */
header {
    background-color: #30acfd; /* Standard color for the header */
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /*position: fixed;
    top: 0;
    left: 0;*/
    width: 100%;
    z-index: 1000; /* Ensures the header is above other content */
    
  }

  .navbar {
    display: flex;
    align-items: center;
  }
  
  .header-link {
    color: white;
    text-decoration: none;
    padding: 10px 15px; /* Added padding for better touch targets on mobile */
    margin: 0 10px; /* Added margin for separation */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease, transform 0.3s ease;
  }



  .header-link:hover {
    color: #f0f0f0; /* Darker blue */
    transform: translateY(-3px); /* Move the button up by 2 pixels */
  }

  .header-icon {
    color: white;
    font-size: 1.5em;
    margin: 0 10px; /* Added margin for separation */
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .header-icon:hover {
    color: #f0f0f0;
    transform: translateY(-3px);
  }

  .profile-picture-icon {
    width: 32px; /* Adjust size as needed */
    height: 32px; /* Adjust size as needed */
    border-radius: 50%;
    object-fit: cover;
  }


  .button-check-header,
  .button-admin-panel,
  .button-login {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #30acfd;
    background-color: white;
    color: #30acfd;
    cursor: pointer;
    margin: 0 10px; /* Reduced margin for small screens */
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .button-check-header:hover,
  .button-admin-panel:hover,
  .button-login:hover {
    background-color: #f0f0f0; /* Light gray */
    color: #2795d9; /* Darker blue */
    border-color: #2795d9; /* Darker blue */
    transform: translateY(-3px); /* Move the button up by 2 pixels */
  }

  .user-dropdown {
    position: relative;
    display: inline-block;
}

.button-user-icon {  
    background: none;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 25px; /* Reduced size for mobile */
    margin: 0 10px; /* Added margin for separation */
}

.dropdown-menu {
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px; /* Rounded corners */
    overflow: hidden; /* Ensure corners are rounded */
    padding: 10px 0; /* Added padding for spacing */
    transition: all 0.3s ease; /* Smooth transition */
}

.dropdown-username {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
  font-size: 0.80rem;
}



.dropdown-username:hover {
  background-color: #30acfd;
  color: #fff;
}



.dropdown-menu::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 15px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #f9f9f9 transparent; /* Triangle arrow */
}



/* Responsive adjustments for the header */
@media (max-width: 1024px) {

  header {
    flex-direction: column;
    padding: 10px 0; /* Increase vertical padding */
  }

  .header-link,
  .button-check-header,
  .button-admin-panel,
  .button-login {
    text-align: center; /* Center the text in buttons */
    margin: 2px 0; /* Add space between items */
    font-size: 0.50rem; /* Smaller font size for mobile devices */
    width: 90%; /* Ensures links take full width to center text properly */
    display: inline-block; /* Ensures the link behaves like a block element but is inline */
    white-space: nowrap; /* Prevents text from wrapping to the next line */  
  }

  .button-user-icon {
    margin: 5px auto; /* Center the user icon button */
  }

}

  /* Footer Style */

  footer {
    
    background-color: #30acfd; /* Standard color for the header */
    color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /*position: fixed;
    bottom: 0;
    left: 0;*/
    width: 100%;
    z-index: 1000; /* Ensures the footer is above other content */
  }
  


    .footer-link {
      color: white;
      text-decoration: none;
      padding: 0px 15px; /* Added padding for better touch targets on mobile */
      margin: 0 10px; /* Added margin for separation */
      font-size: 0.9rem; /* Starting font size for desktop */
    }
    
    .footer-link:hover {
      text-decoration: underline; /* Enhances usability on hover */
    }


    @media (max-width: 1024px) {
      footer {
        flex-direction: column; /* Stacks the links vertically on smaller screens */
        padding: 15px 10px; /* Increase padding to handle more content vertically */
        align-items: center; /* Centers the content horizontally */
        text-align: center; /* Ensures text within each link is centered */
      }
    
      .footer-link {
        margin: 5px 0; /* More vertical space between links */
        font-size: 0.45rem; /* Smaller font size for mobile devices */
        width: auto; /* Allows the links to take their natural width */
        white-space: nowrap; /* Prevents text from wrapping to the next line */
      }
    
      /* Reducing the size of the copyright text on smaller screens */
      .copyright {
        font-size: 0.45rem; /* Smaller font size to prevent overflow */
        margin-top: 10px; /* Adds some space between links and copyright text */
        width: 100%; /* Full width to maintain center alignment */

      }
    }

    
    

/* Basic styling for LanguageSwitcher within the header and footer */
.language-switcher {
  padding: 5px 10px;
  background-color: white;
  color: #30acfd;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 0.8rem; /* Adjust size as needed */
  margin-left: 20px;
  transition: background-color 0.3s ease;
}

.language-switcher:hover {
  background-color: #2795d9;
  color: white;
}

/* Responsive adjustments for the language switcher */
@media (max-width: 1024px) {
  .language-switcher {
    font-size: 0.6rem; /* Adjust font size for smaller screens */
    padding: 0px 15px; /* Added padding for better touch targets on mobile */
  }
}


    /* Imprint Style */
    .imprint {
      max-width: 800px;
      min-height: 100vh;
      max-width: 600;
      margin: auto;
      padding: 10px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      flex-grow: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      /* justify-content: center; */ /* Center content vertically */

      padding-top: 80px;
      padding-bottom: 80px;
    }
    
    .imprint h1 {
      text-align: center;
      margin-bottom: 20px;
    }
    
    .imprint p {
      text-align: justify;
      margin-bottom: 20px;
    }

    /* Data Protection Style */
    .dataProtection {
      max-width: 800px;
      min-height: 100vh;
      max-width: 600;
      margin: auto;
      padding: 10px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      flex-grow: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center; /* Center content horizontally */
      /* justify-content: center; */ /* Center content vertically */

      padding-top: 80px;
      padding-bottom: 80px;

    }


    
    .dataProtection h1 {
      text-align: center;
      margin-bottom: 20px;
    }
    
    .dataProtection p {
      text-align: justify;
      margin-bottom: 20px;
    }
    

    /* Terms and Conditions Style */

    .terms-conditions {
      max-width: 800px;
      min-height: 100vh;
      max-width: 600;
      margin: auto;
      padding: 10px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      flex-grow: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center; /* Center content horizontally */
      /* justify-content: center; */ /* Center content vertically */

      padding-top: 80px;
      padding-bottom: 80px;
    }
    
    .terms-conditions h1 {
      text-align: center;
      margin-bottom: 20px;
    }
    
    .terms-conditions p {
      text-align: justify;
      margin-bottom: 20px;
    }
    
    /* Contact us Style */
    .contact-page {

      text-align: center;
      padding: 10px;
      margin: auto;
      min-height: 100vh;
      max-width: 600px;
      background-color: #fff;
      flex-grow: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center; /* Center content horizontally */
      /* justify-content: center; */ /* Center content vertically */
      padding-top: 80px;
      padding-bottom: 80px;
    }


    
    .contact-page h1 {
      margin-bottom: 20px;
    }
    
    .contact-info {
      text-align: left;
      margin-top: 20px;
    }
    
    .contact-info h2 {
      margin-top: 20px;
    }
    
    .contact-info p {
      margin-bottom: 10px;
    }
    
    /* Mobile App Page Style */

    .mobile-app-description {
      max-width: 800px;
      min-height: 100vh;
      margin: 20px auto;
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      padding-top: 80px;
      padding-bottom: 80px;
    }
    
    .mobile-app-description h1 {
      text-align: center;
      margin-bottom: 20px;
    }
    
    .mobile-app-description p {
      text-align: justify;
      margin-bottom: 20px;
    }
    

    /* 404 Page not found Style */

    .not-found-page {
      text-align: center;
      color: white ;
      padding: 50px;
      margin-top: 50px;
      min-height: 100vh;
      padding-top: 80px;
      padding-bottom: 80px;

      
    }
    
    .not-found-page h1 {
      font-size: 2em;
      margin-bottom: 20px;
    }
    
    .not-found-page p {
      font-size: 1.2em;
    }
    
    /* Style for the funny image */
    .not-found-page img {
      max-width: 100%;
      height: auto;
      margin-top: 20px;
    }
    
    @keyframes moveSpaceship {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
    
    .spaceship {
      animation: moveSpaceship 10s linear infinite;
    }

/* Home Style */

.home-page {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin: 30px auto;
  min-height: 100vh;
  max-width: 600px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.home-page h1 {
  color: #333;
  margin-bottom: 16px;
  font-size: 2rem; /* Responsive font size */
}

.home-page h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 1.5rem; /* Responsive font size */
}

.home-page h3 {
  color: #555;
  margin-bottom: 24px;
  font-size: 1.2rem; /* Responsive font size */
}

.logihash-badge {
  width: 130px; 
  height: 130px; 
  border-radius: 50%;
  border: solid #30acfd;
  object-fit: cover; 
  margin-bottom: 20px;
}

.logihash-ai-design {
  width: 90%;
  max-width: 400px;
  height: auto;
  margin-bottom: 20px;
}

.competition-badge {
  width: 70%;
  max-width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.button-container {
  text-align: center;
  margin-top: 20px;
}



@keyframes happyBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.happy-move {
  animation: happyBounce 1s ease-in-out infinite;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
  .home-page {
    padding: 15px;
    margin: 20px auto;
    max-width: 90%;
  }

  .home-page h1 {
    font-size: 1.8rem;
  }

  .home-page h2 {
    font-size: 1.3rem;
  }

  .home-page h3 {
    font-size: 1rem;
  }

  .logihash-badge {
    width: 100px;
    height: 100px;
  }

  .logihash-ai-design {
    width: 90%;
    max-width: 350px;
  }
  
  .competition-badge {
    width: 90%;
    max-width: 200px;
  }
}

    
    

    
    
    /* Admin Panel Style */
    .admin-panel {
      max-width: 800px;
      min-height: 100vh;
      max-width: 600;
      margin: auto;
      padding: 10px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      flex-grow: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center; /* Center content horizontally */
      /* justify-content: center; */ /* Center content vertically */

      padding-top: 80px;
      padding-bottom: 80px;

    }

    .admin-panel h2 {
      margin-top: 20px;
      min-height: 100vh;
    }
    
    .admin-panel .user-list, .qr-code-list, .company-list {
      margin-bottom: 30px;
    }
    
    .admin-panel .user-item, .qr-code-item, .company-item {
      background-color: #f0f0f0;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
    }
    
    .admin-panel button {
      background-color: #30acfd;
      color: white;
      padding: 5px 10px;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin-left: 10px;
    }
    
    .admin-panel button:hover {
      background-color: #0056b3;
    }

    
    /* Style*/

    /* Style*/

    /* Check QR Code Style*/


    .check-qr-code-page {
      min-height: 100vh;
      flex-grow: 1;
      background-color: #fff;
      text-align: center;
      padding: 20px;
      overflow-y: auto;
      flex-direction: column;
      padding-bottom: 80px;
    }
    
    .check-qr-code-page h1 {
      margin-bottom: 20px;
    }
    
    .check-qr-code-page .qr-code-form {
      max-width: 400px;
      margin: 0 auto;
    }
    
    .check-qr-code-page .input-group {
      display: flex;
      margin-bottom: 20px;
    }
    
    .check-qr-code-page .input-group input {
      flex-grow: 1;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px 0 0 5px;
    }
    
    
    .check-qr-code-page .button-search {
      padding: 10px;
      border: 1px solid #30acfd;
      border-radius: 0 5px 5px 0;
      background-color: #30acfd;
      color: white;
      cursor: pointer;
    }
    
    .check-qr-code-page .button-search:hover {
      background-color: #2795d9;
    }
    
    
    .button-camera {
      padding: 10px 15px;
      border-radius: 5px;
      border: 1px solid white;
      background-color: #30acfd;
      color: white;
      cursor: pointer;
      margin-left: 10px;
      transition: background-color 0.3s ease, transform 0.3s ease;
    }
    
    .button-camera:hover {
      background-color: #2795d9; /* Darker blue */
      transform: translateY(-3px); /* Move the button up by 2 pixels */
    }
    
    .qr-reader {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
    
    .qr-reader video {
      width: 100%;
      height: auto;
      max-width: 600px; /* Maximum width for larger screens */
      border: 2px solid #30acfd;
      border-radius: 10px;
    }
    
    .qr-box {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .qr-frame {
      max-width: 80%;
      max-height: 80%;
      width: auto;
      height: auto;
      z-index: 2; /* Ensure the frame is above the video */
    }
    
    /* Responsive adjustments */
    @media (max-width: 768px) {
      .qr-reader video {
        max-width: 100%; /* Full width for smaller screens */
        border: 1px solid #30acfd;
      }
    
      .qr-frame {
        max-width: 70%; /* Slightly smaller frame for mobile */
      }
    }
/* Details Table Styling */

.verified-icon {
  margin-top: 1vh;
  text-align: center;
}

.verified-icon img {
  width: 10vw;
}

.check-symbol {
  background-color: white;
  border-radius: 100%;
  padding: 5px;
  font-size: 4em;
  transition: color 0.7s ease-in-out; /* Smooth transition for color change */
}

.qr-code-details {
  margin-top: 1vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1vw;
  margin-top: 1vh;
  background-color: #f9f9f9;
  padding: 2vw;
  border-radius: 1vw;
  width: auto;
}
.section-label {
  text-align: center;
  font-weight: bold;
  margin: 10px 0;
  font-size: 1.1vw;
}

.details-item {
  background-color: #30acfd;
  color: white;
  padding: 1vw;
  border-radius: 0.5vw;
  text-align: center;
  font-size: 1.1vw;
}

.details-item span {
  font-weight: bold;
}

.button-report {
  margin-top: 1vw;
  padding: 1vw 2vw;
  border-radius: 0.5vw;
  border: 0.2vw solid #f44336;
  background-color: #f44336;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  text-align: center;
}

.button-report:hover {
  background-color: #d32f2f;
}

.button-report p {
  margin: 0;
  margin-left: 0.5vw;
}

.mobile-app-note-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure it takes up the full width */
  max-width: 700px; /* Adjust the max-width as needed to control the wrapping */
  margin: 20px auto; /* Center the container and add some margin */
  text-align: center; /* Center the text within the container */
}

.mobile-app-note {
  margin: 0; /* Remove default margin */
  padding: 10px; /* Add padding if needed */
  font-size: 1rem; /* Adjust font size as needed */
  line-height: 1.5; /* Adjust line height for better readability */
}

.popup-report {
  padding: 15px;
  border: 1px solid #ddd;
  background-color: #f0f0f0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 5px;
  width: 300px;
  max-width: 90%;
}

.button-exit-popup-report {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0;
  border-radius: 50%;
  border: none;
  background-color: rgb(241, 81, 81);
  color: white;
  font-size: 1.2em;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

.button-exit-popup-report:hover {
  background-color: #f00;
  color: #000;
}

.report-select-popup-report {
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  display: block; /* Ensure the dropdown list is on its own line */
}

.report-select-popup-report:focus {
  outline: none;
  border-color: #30acfd;
}

.button-submit-popup-report {
  background-color: #4CAF50; /* Green */
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
}

.button-submit-popup-report:hover {
  background-color: #45a049;
}

@media (max-width: 1024px) {
  .verified-icon img {
    width: 30vw;
  }

  .check-symbol {
    font-size: 2.5em;
  }

  .details-grid {
    padding: 3vw;
    border-radius: 2vw;
    width: 85%;
  }

  .details-item {
    font-size: 2vw;
    padding: 2vw;
  }
  .section-label {
    font-size: 2vw;
    padding: 2vw;
  }

  .button-report {
    flex-direction: column;
    font-size: 2vw;
    padding: 2vw 4vw;
    border-radius: 2vw;
  }

  .button-report p {
    margin-left: 0;
    margin-top: 1vw;
  }
}

    
    /* Forgot Password Style*/

    .forgot-password-container {
      background-color: white;
      max-width: 400px;
      margin: 0 auto;
      padding: 20px;
      text-align: center;
      padding-top: 80px;
      padding-bottom: 80px;      
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .forgot-password-container h1 {
      color: #30acfd;
      margin-bottom: 20px;
  }
  
  .forgot-password-container form {
      display: flex;
      flex-direction: column;
      gap: 10px;
  }
  
  .forgot-password-container input {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
  }
  
  .forgot-password-container button {
      padding: 10px;
      background-color: #30acfd;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  }
  
  .forgot-password-container button:hover {
      background-color: #0056b3;
  }
  
  .forgot-password-container .message {
      color: green;
      margin-bottom: 15px;
  }
  
  .forgot-password-container .error {
      color: red;
      margin-bottom: 15px;
  }
  
    /* Login Page Style*/

    .login-page {
      text-align: center;
      padding: 20px;
      min-height: 100vh;

      padding-top: 80px;
      padding-bottom: 80px;
    }
    
    .login-page h1 {
      color: #30acfd;
      margin-bottom: 16px;
    }
    
    .login-page p {
      color: #555;
      margin-top: 20px;
    }
    
    .login-page a {
      color: #30acfd;
      text-decoration: none;
    }
    
    .login-page a:hover {
      text-decoration: underline;
    }
    
    /* Login Form Style*/

    .login-form {
      max-width: 400px;
      margin: 0 auto;
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
    .login-form label {
      display: block;
      text-align: left;
      margin-bottom: 5px;
    }
    
    .login-form .inputLogin {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      border-radius: 5px;
      border: 1px solid #ddd;
      box-sizing: border-box;
    }

    .login-form .password-field {
      position: relative;
    }
    
    .login-form .checkbox-container {
      display: flex;
      align-items: left;
      margin-bottom: 20px;
    }
    
    .login-form .checkbox-container label {
      margin-left: 10px;
    }
    
    .login-form .forgot-password-link {
      display: block;
      margin-top: 10px;
      text-align: left;
      color: #30acfd;
      text-decoration: none;
    }
    
    .login-form .forgot-password-link:hover {
      text-decoration: underline;
    }
    
    
    .login-form .social-login {
      background-color: #EA4335; /* Google's brand red color */
      color: white;
      margin-top: 10px;
      text-decoration: none;
    }
    
    .login-form .social-login:hover {
      background-color: 	#EA4335; /* A darker shade of red for hover effect */
    }

    /* Verification Page Style*/

    .account-verification-page {
      display: flex;
      flex-direction: column;
      align-items: center; /* Center the content horizontally */
      text-align: center;
      padding: 20px;
      margin-top: 20px;
      min-height: 100vh;
    }
    
    .account-verification-page h1 {
      margin-bottom: 20px;
    }
    
    
    
    /* Registration Page Style*/

    .registration-page {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      padding: 20px;
      min-height: 100vh;

      padding-top: 80px;
      padding-bottom: 80px;
    }
    
    .registration-page h1 {
      color: #30acfd;
      margin-bottom: 20px;
      font-size: 2em;
      text-align: center;
    }
    
    .registration-page body {
      margin: 0;
      font-family: 'Arial', sans-serif;
    }
    
    @media (max-width: 1024px) {
      .registration-page h1 {
        font-size: 1.5em;
      }
    }
    
    /* Registration Form Style*/

    .registration-form {
      max-width: 500px;
      margin: 0 auto;
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
    .registration-form label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }
    
    .registration-form input[type="text"],
    .registration-form input[type="email"],
    .registration-form input[type="password"],
    .registration-form input[type="tel"],
    .registration-form input[type="date"] {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      border-radius: 5px;
      border: 1px solid #ddd;
      box-sizing: border-box;
    }
    
    .registration-form .error {
      border: 2px solid red;
    }
    
    .registration-form .password-field {
      position: relative;
    }
    
    
    .registration-form .checkbox-container {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    
    .registration-form .checkbox-container label {
      margin-left: 10px;
    }
    
    .registration-form button {
      width: 100%;
      padding: 12px;
      background-color: #30acfd;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s;
    }
    
    .registration-form button:hover {
      background-color: #0056b3;
    }
  
  
    .registration-form select {
      font-size: 16px;
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-bottom: 10px;
      width: 100%;
      box-sizing: border-box;
      transition: border-color 0.3s;
    
      /* You can add more styling as per your design requirements */
    }
    
    .registration-form select:focus {
      border-color: #30acfd; /* Change focus color as needed */
      outline: none;
    }
    
    /* Activate QR Code Page Style*/

    .activate-qr-code-page {
      text-align: center;
      flex-grow: 1;
      padding: 20px;
      min-height: 100vh;
      background-color: white;
      overflow-y: auto;
      flex-direction: column;
      padding-bottom: 80px;
    }

    
    .activate-qr-code-page .qr-code-form {
      max-width: 500px;
      margin: 0 auto;
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
    .activate-qr-code-page .qr-code-form .form-group {
      margin-bottom: 15px;
    }
    
    .activate-qr-code-page .qr-code-form .form-group input,
    .activate-qr-code-page .qr-code-form .form-group select {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      box-sizing: border-box;
    }
    
    .activate-qr-code-page .qr-code-form .activate-button {
      width: 100%;
      padding: 10px;
      background-color: #30acfd;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .activate-qr-code-page .qr-code-form .activate-button:hover {
      background-color: #0056b3;
    }
    
/* Purchase QR codeStyle */

.purchase-qr-code-page {
  text-align: center;
  padding: 10px;
  margin-top: auto;
  min-height: 100vh;
  background-color: #fff;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  padding-bottom: 80px;
}

.purchase-qr-code-page h1 {
  margin-bottom: 20px;
}

.purchase-qr-code-note {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure it takes up the full width */
  max-width: 700px; /* Adjust the max-width as needed to control the wrapping */
  margin: 20px auto; /* Center the container and add some margin */
  text-align: center; /* Center the text within the container */
}
.purchase-qr-code-page .qr-code-display {
  margin-top: 20px;
}

.image-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  max-width: 800px;
  margin: 20px 0;
}

.document-image-wrapper, .envelope-image-wrapper, .parcel-image-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%; /* Ensure two items per row on larger screens */
  margin: 10px 0;
  border: 2px solid #ddd; /* Border around each item */
  border-radius: 8px;
  padding: 10px;
}

.document-image, .envelope-image, .parcel-image {
  width: 100%; /* Ensure responsive width */
  height: auto; /* Maintain aspect ratio */
  max-height: 300px; /* Maximum height for larger images */
  margin-bottom: 10px;
}

.message-label {
  margin-bottom: 5px;
  font-weight: bold;
}

.message-select {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  width: 100%; /* Make sure it fits the width of the parent */
}


.message-select:focus {
  outline: none;
  border-color: #30acfd;
}

.qr-code-overlay-document, .qr-code-overlay-envelope, .qr-code-overlay-parcel {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-code-overlay-document {
  top: 50px; /* Adjust this value to position the QR code vertically */
  left: 220px; /* Adjust this value to position the QR code horizontally */
}

.qr-code-overlay-envelope {
  top: 20px; /* Adjust this value to position the QR code vertically */
  left: 20px; /* Adjust this value to position the QR code horizontally */
}

.qr-code-overlay-parcel {
  top: 90px; /* Adjust this value to position the QR code vertically */
  left: 50px; /* Adjust this value to position the QR code horizontally */
}

.qr-code-text-document, .qr-code-text-envelope, .qr-code-message-envelope {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-code-text-document {
  top: 100px; /* Adjust this value to position the qr-code-text vertically */
  left: 220px; /* Adjust this value to position the qr-code-text horizontally */
  font-size: 10px; /* Small font size for QR code text */
  margin: 2px 0; /* Small margin for spacing */
  text-align: center; /* Center-align the text */
}

.qr-code-message-document {
  top: 110px; /* Adjust this value to position the qr-code-message vertically */
  left: 230px; /* Adjust this value to position the qr-code-message horizontally */
  font-size: 10px; /* Small font size for QR code text */
  margin: 2px 0; /* Small margin for spacing */
  text-align: center; /* Center-align the text */
}

.qr-code-text-envelope {
  top: 70px; /* Adjust this value to position the qr-code-text vertically */
  left: 20px; /* Adjust this value to position the qr-code-text horizontally */
  font-size: 10px; /* Small font size for QR code text */
  margin: 2px 0; /* Small margin for spacing */
  text-align: center; /* Center-align the text */
}

.qr-code-message-envelope {
  top: 85px; /* Adjust this value to position the qr-code-message vertically */
  left: 20px; /* Adjust this value to position the qr-code-message horizontally */
  font-size: 10px; /* Small font size for QR code text */
  margin: 2px 0; /* Small margin for spacing */
  text-align: center; /* Center-align the text */
}


.qr-code-text-parcel, .qr-code-message-parcel {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-code-text-parcel {
  top: 145px; /* Adjust this value to position the qr-code-text vertically */
  left: 50px; /* Adjust this value to position the qr-code-text horizontally */
  font-size: 10px; /* Small font size for QR code text */
  margin: 2px 0; /* Small margin for spacing */
  text-align: center; /* Center-align the text */
}

.qr-code-message-parcel {
  top: 165px; /* Adjust this value to position the qr-code-message vertically */
  left: 50px; /* Adjust this value to position the qr-code-message horizontally */
  font-size: 10px; /* Small font size for QR code text */
  margin: 2px 0; /* Small margin for spacing */
  text-align: center; /* Center-align the text */
}

.button-purchase {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid white;
  background-color: #30acfd;
  color: white;
  cursor: pointer;
  margin-top: 10px;
}

.button-purchase:hover {
  background-color: #2795d9; /* Darker blue */
  transform: translateY(-3px); /* Move the button up by 2 pixels */
}

.button-activate-container {
  width: 100%;
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 20px; /* Add some space above the button */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
  .image-wrapper {
    flex-direction: column;
    align-items: center;
    width: 350px;
    max-width: 800px;
  }

  .document-image-wrapper {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 20px;
  }

  .envelope-image-wrapper {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 20px;
  }
  
  
  .parcel-image-wrapper {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 20px;
  }

  .document-image, .envelope-image, .parcel-image {
    max-height: 300px; /* Reduce maximum height for smaller screens */
  }

  .qr-code-overlay-document {
    top: 50px; /* Adjust for smaller screens */
    left: 130px;
  }

  .qr-code-overlay-envelope {
    top: 20px; /* Adjust for smaller screens */
    left: 20px;
  }

  .qr-code-overlay-parcel {
    top: 85px; /* Adjust for smaller screens */
    left: 50px;
  }

  .qr-code-container .qr-code-overlay-document {
    width: 20px;
    height: 20px;
  }

  .qr-code-container .qr-code-text-document {
    font-size: 6px;
    top: 100px; /* Adjust this value to position the qr-code-text vertically */
    left: 130px; /* Adjust this value to position the qr-code-text horizontally */
    margin: 0px 0; /* Small margin for spacing */
  }

  .qr-code-container .qr-code-message-document {
    font-size: 5px;
    top: 77px; /* Adjust this value to position the qr-code-message vertically */
    left: 20px; /* Adjust this value to position the qr-code-message horizontally */
    margin: 0px 0; /* Small margin for spacing */
  }

  .qr-code-container .qr-code-overlay-envelope {
    width: 20px;
    height: 20px;
  }

  .qr-code-container .qr-code-text-envelope {
    font-size: 6px;
    top: 70px; /* Adjust this value to position the qr-code-text vertically */
    left: 20px; /* Adjust this value to position the qr-code-text horizontally */
    margin: 0px 0; /* Small margin for spacing */
  }

  .qr-code-container .qr-code-message-envelope {
    font-size: 5px;
    top: 77px; /* Adjust this value to position the qr-code-message vertically */
    left: 20px; /* Adjust this value to position the qr-code-message horizontally */
    margin: 0px 0; /* Small margin for spacing */
  }


  .qr-code-container .qr-code-overlay-parcel {
    width: 20px;
    height: 20px;
  }

  .qr-code-container .qr-code-text-parcel {
    font-size: 6px;
    top: 140px; /* Adjust this value to position the qr-code-text vertically */
    left: 50px; /* Adjust this value to position the qr-code-text horizontally */
    margin: 0px 0; /* Small margin for spacing */
  }

  .qr-code-container .qr-code-message-parcel {
    font-size: 5px;
    top: 150px; /* Adjust this value to position the qr-code-text vertically */
    left: 50px; /* Adjust this value to position the qr-code-text horizontally */
    margin: 0px 0; /* Small margin for spacing */
  }
}

    /* Dashboard Style*/

    .dashboard-page {
      background-color: #fff;
      display: flex;
      min-height: 100vh;
      padding-top: 80px;
      padding-bottom: 80px;
    }
    
    
    .dashboard-content {
      flex-grow: 1;
      background-color: #fff;
      padding: 20px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center; /* Center content horizontally */
      justify-content: center; /* Center content vertically */
    }
    
    .dashboard-content select {
      display: grid;
      place-items: center;  
      margin-top: 20px;
      padding: 8px 12px;
      border-radius: 4px;
      border: 1px solid #ddd;
      cursor: pointer;
      width: 100%; /* Adjust width as needed */
      margin-left: auto;
      margin-right: auto;
    }
    
    /* QR Code styling */
    .dashboard-content .qr-code {
      display: grid;
      place-items: center;  
      margin-bottom: 20px;
      margin-top: 20px;
      border: 1px solid white;
      padding: 10px;
      border-radius: 8px;
      background-color: white;
    }
  
    .dashboard-actions {
      position: fixed;
      top: 20px;
      right: 20px;
    }
    
    .dashboard-actions .button-activate-qr-code {
      position: fixed;
      top: 80px;
      left: 400px;
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid white;
      background-color: #4CAF50;
      color: white;
      cursor: pointer;
      margin-left: 40px;
      
    }
    
  .dashboard-actions .button-activate-qr-code:hover {
    background-color: #45a049; /* Darker green */
  }


    .dashboard-actions .button-purchase-qr-code {
      position: fixed;
      top: 80px;
      right: 20px;
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid white;
      background-color: #FFC107 ;
      color: #212121;
      cursor: pointer;
      margin-left: 40px;
    }

    .dashboard-actions .button-purchase-qr-code:hover {
      background-color: #ffb300; /* Darker orange */
    }
    

    /* Active QR Codes Style*/
    
    .active-circle {
      display: inline-block;
      width: 15px;
      height: 15px;
      background-color: green;
      border-radius:50%;
      align-self: center;
    }
    /* InActive QR Codes Style*/
    .inactive-circle {
      display: inline-block;
      width: 15px;
      height: 15px;
      background-color: gray;
      border-radius:50%;
      align-self: center;
    }
    .active-circle.warning {
      background-color: red;
    }
    
    .active-circle.expiring {
      background-color: rgb(255, 196, 0);
    }

    /* Manage Companies Style*/
  
   /* Manage Departments Style*/

  
    /* Manage Branches Style*/
  

    /* QR Code Identity Style*/
    .qr-code-identity {
      max-width: 1000px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin: 20px;
    }
    
    .qr-code-identity h2 {
      color: #333;
      margin-bottom: 15px;
    }
    
    .qr-code-identity div {
      margin: 10px 0;
      
    }
    
    .identity-select {
      padding: 8px 12px;
      border-radius: 4px;
      border: 1px solid #ccc;
      background-color: white;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
      margin-top: 10px;
    }
    
    .identity-select:focus {
      outline: none;
      border-color: #30acfd;
    }
    
    .qr-code-identity .qr-code-container {
      padding: 15px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

    }
    
    .qr-code-value {
      margin-top: 10px; /* Adjust as needed */
      font-size: 14px; /* Adjust as needed */
    }
    
    .qr-code-identity .qr-code-text {
      margin-top: 5px; /* Adjust as needed */
      font-size: 12px; /* Adjust as needed */
      font-style: italic;
    }
    
    /* Settings Style*/


.settings-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.settings-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.settings-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.settings-form input[type="text"],
.settings-form input[type="email"],
.settings-form input[type="password"],
.settings-form input[type="tel"],
.settings-form input[type="date"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.settings-form .error {
  border: 2px solid red;
}

.settings-form .password-field {
  position: relative;
}


.settings-form .checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.settings-form .checkbox-container label {
  margin-left: 10px;
}



.settings-form select {
  font-size: 16px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s;

}

.settings-form select:focus {
  border-color: #30acfd; /* Change focus color as needed */
  outline: none;
}

.user-info {
  display: grid;
  grid-template-columns: 1fr; /* Two columns */
  gap: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.user-info .info-item {
  padding: 10px;
  background-color: #5eb6f0; /* Main color */
  color: #000;
  border-radius: 5px;
  text-align: center; /* Center the text */
}

.profile-picture-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
}


/*Dashboard Sidebar Styling*/

/* Import Google font - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  min-height: 100vh;
  background: #eef5fe;
}

/* Flex utility class */
.flex {
  display: flex;
  align-items: center;
}

.nav_image {
  display: flex;
  min-width: 55px;
  justify-content: center;
  cursor: pointer;
}

.nav_image img {
  width: 3.5vw;
  height: 3.5vw;
  border-radius: 100%;
  border: solid #30acfd;
  object-fit: cover;
}

/* Sidebar - Desktop Mode */
.sidebar {
  position: fixed;
  background-color: #fff;
  top: 5vw;
  left: 0;
  height: calc(100% - 8vw);
  width: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease;
  flex-basis: 250px;
  z-index: 1; /* Ensure it stays above other content */

}

.sidebar.close {
  width: calc(55px + 20px);
}

.logo_items {
  gap: 8px;
}

.logo_name {
  font-size: 2vw;
  color: #333;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
}

.sidebar.close .logo_name,
.sidebar.close #lock-icon,
.sidebar.close #sidebar-close {
  opacity: 0;
  pointer-events: none;
}

#lock-icon,
#sidebar-close {
  padding: 10px;
  color: #30acfd;
  font-size: 25px;
  cursor: pointer;
  margin-left: -4px;
  transition: all 0.3s ease;
}

#sidebar-close {
  display: none;
  color: #333;
}

.menu_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;
  overflow-y: auto;
  height: calc(100% - 82px);
}

.menu_container::-webkit-scrollbar {
  display: none;
}

.menu_title {
  position: relative;
  height: 50px;
  width: 55px;
}

.menu_title .title {
  margin-left: 15px;
  transition: all 0.3s ease;
}

.sidebar.close .title {
  opacity: 0;
}

.menu_title .line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 20px;
  border-radius: 25px;
  background: #aaa;
  transition: all 0.3s ease;
}

.menu_title .line {
  opacity: 0;
}

.sidebar.close .line {
  opacity: 1;
}

.item {
  list-style: none;
}

.link {
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 8px;
  color: #000;
}

.link:hover {
  background-color: #30acfd;
  cursor: pointer;
  color: #000;
}

.link.active {
  color: #fff;
  background-color: #30acfd;
}

.link span {
  white-space: nowrap;
}

.link i {
  height: 50px;
  min-width: 55px;
  display: flex;
  font-size: 22px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.sidebar_profile {
  padding-top: 15px;
  margin-top: 15px;
  gap: 15px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.sidebar_profile .name {
  font-size: 18px;
  color: #333;
}

.sidebar_profile .email {
  font-size: 15px;
  color: #333;
}

/* Navbar */
#sidebar-open {
  font-size: 30px;
  color: #333;
  cursor: pointer;
  margin-right: 20px;
  display: none;
}

.search_box {
  height: 46px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #aaa;
  outline: none;
  border-radius: 8px;
  padding: 0 15px;
  font-size: 18px;
  color: #333;
}

/* Mobile Mode */
@media (max-width: 1024px) {
  .sidebar {
    display: none;
  }

}






/*Dashboard Content Styling*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');

:root{
    /* ===== Colors ===== */
    --primary-color: #30acfd;
    --panel-color: #FFF;
    --text-color: #fff;
    --black-light-color: #707070;
    --border-color: #e6e5e5;
    --toggle-color: #DDD;
    --box1-color: #30acfd;

    
    --title-icon-color: #fff;
    
    /* ====== Transition ====== */
    --tran-05: all 0.5s ease;
    --tran-03: all 0.3s ease;
    --tran-03: all 0.2s ease;
}
body{
    min-height: 100vh;
    background-color: var(--primary-color);
}
body.dark{
    --primary-color: #3A3B3C;
    --panel-color: #242526;
    --text-color: #CCC;
    --black-light-color: #CCC;
    --border-color: #4D4C4C;
    --toggle-color: #FFF;
    --box1-color: #3A3B3C;
    --box2-color: #3A3B3C;
    --box3-color: #3A3B3C;
    --title-icon-color: #CCC;
}
/* === Custom Scroll Bar CSS === */



.dashboard{
    position: relative;
    left: 250px;
    background-color: var(--panel-color);
    min-height: 100vh;
    width: calc(100% - 250px);
    padding: 10px 14px;
    transition: var(--tran-05);
}

.dashboard .dash-content{
    padding-top: 50px;
}
.dash-content .title{
    display: flex;
    align-items: center;
    margin: 60px 0 30px 0;
}
.dash-content .title i{
    position: relative;
    height: 35px;
    width: 35px;
    background-color: var(--primary-color);
    border-radius: 6px;
    color: var(--title-icon-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}
.dash-content .title .text{
    font-size: 24px;
    font-weight: 500;
    color: var(--text-color);
    margin-left: 10px;
}
.dash-content .boxes{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.dash-content .boxes .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    width: calc(100% / 3 - 15px);
    padding: 20px 20px;
    margin-bottom: 20px; 
    background-color: var(--box1-color);
    transition: var(--tran-05);
    cursor: pointer;
    
}

.dash-content .boxes .box:hover {
  transform: scale(1.05); /* Scale up by 5% */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

.boxes .box i{
    font-size: 35px;
    color: var(--text-color);
}
.boxes .box .text{
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color);
}
.boxes .box .number{
    font-size: 40px;
    font-weight: 500;
    color: var(--text-color);
}

.dash-content .activity .activity-data{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
